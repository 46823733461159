
  import {
    assign_pay_to_me,
    close_pay,
    delete_pay,
    finish_bill,
    get_pay_item,
    get_same_company_bill_list,
    edit_demand_pay,
    link_bill_for_pay,
    reopen_pay,
    reverse_bill,
  } from '@/api/erp-pay';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { isProd } from '@/utils/const';
  import { formatTime } from '@/utils/time';
  import { eq, formatMoneyByRound } from '@/utils/number';
  import { defineComponent, ref, unref, computed } from 'vue';
  import { useBills } from '../bills/hooks/useBills';
  import { useInvoice } from '../invoices/hooks/useInvoice';
  import { usePay } from './hooks/usePay';
  import { useUtils } from '@/hooks/useUtils';
  import { appStore } from '@/store/modules/app';
  import { ActionItem, BasicColumn, BasicTableProps } from '@/components/WeTable';
  import { useJump } from '@/hooks/useJump';
  import { sum, uniqBy } from 'lodash-es';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'ERPPayDetail',
    props: {
      payId: {
        type: [String, Number],
        default: '',
      },
    },
    setup() {
      const { user, isAdmin, isGod, isAnalysis, isAccountant, isOne, getMyTeamByType, isFinance, hasPermission } =
        useUser();
      const { model, teamMap, weCompanyMap, getDollarRate, currencyMap, appInfo, userMap, bulineMap } = useAppInfo();
      const { initBillListSimple } = useBills();
      const { editInvoice } = useInvoice();
      const { jumpToContract, jumpToBill, jumpToCompany, jumpToPay } = useJump();
      const { editDemandPay, editPurchasePay, editSupplyPay, initPayInfo, godEditPay, addDirectPayment } = usePay();
      const { showJson } = useUtils();
      const linkForm = ref<Nullable<FormActionType>>(null);
      const pay = ref<Recordable>({});
      const sameCompanyBillList = ref<any[]>([]);
      const BillSelectedRowKeys = ref<any[]>([]);
      const splitPayDialog = ref<Boolean>(false);
      const isTeamValid = (t) => !t.is_deleted && !t.is_history;
      const group_bu_items = computed(() => {
        const teams: Recordable[] = [];
        unref(appInfo).team_group_list.forEach((g) => {
          g.team_list.filter(isTeamValid).forEach((t) => {
            teams.push({
              group_id: g.id,
              bu_id: t.bu_id,
              group_name: g.name,
              team_id: t.id,
              team_name: t.short_name,
            });
          });
        });
        teams.forEach((item) => {
          item.bu_name = '';
          if (item.bu_id) {
            const find_item = unref(appInfo).bu_map[item.bu_id];
            if (!isEmptyData(find_item)) {
              item.bu_name = (unref(appInfo).bu_map[item.bu_id] || {}).name;
            }
          }
        });
        return teams;
      });

      const all_group_items = computed(() => {
        return uniqBy(unref(group_bu_items), 'group_id').map((item: Recordable) => ({
          id: item.group_id,
          name: item.group_name,
        }));
      });

      const splitPayItems = ref<any[]>([{}]);
      const showSplitPay = ref<any[]>([]);

      const can_use_group_items = computed(() => {
        return uniqBy(unref(group_bu_items), 'group_id').map((item: Recordable) => ({
          id: item.group_id,
          name: item.group_name,
          disabled: unref(splitPayItems).filter((s) => s.group_id === item.group_id).length !== 0,
        }));
      });

      const add_split_disabled = computed(() => {
        return eq(unref(pay).pay_money || 0, sum(unref(splitPayItems).map((x) => x.split_money || 0)));
      });
      return {
        user,
        isAdmin,
        isGod,
        isProd,
        isAnalysis,
        isOne,
        isAccountant,
        model,
        teamMap,
        userMap,
        pay,
        linkForm,
        sameCompanyBillList,
        initBillListSimple,
        editInvoice,
        editDemandPay,
        editPurchasePay,
        editSupplyPay,
        godEditPay,
        initPayInfo,
        showJson,
        weCompanyMap,
        getDollarRate,
        currencyMap,
        getMyTeamByType,
        isFinance,
        appInfo,
        bulineMap,
        BillSelectedRowKeys,
        jumpToBill,
        jumpToPay,
        jumpToContract,
        jumpToCompany,
        addDirectPayment,
        splitPayItems,
        splitPayDialog,
        group_bu_items,
        all_group_items,
        can_use_group_items,
        add_split_disabled,
        showSplitPay,
        hasPermission,
      };
    },
    data() {
      return {
        editMode: false,
        readOnly: false,
        loadingAccounts: false,
        linkBillDialog: false,
        linkBillCheckAll: false,
        linkBillUnlinked: 0,
        linkBillAllLinked: 0,
        tracePage: 'Transaction Detail',
      };
    },
    computed: {
      showPay(): boolean {
        return !isEmptyData(this.pay) && !this.pay.is_deleted;
      },
      contract(): Recordable {
        return this.pay.contract || {};
      },
      isOversea(): boolean {
        return this.pay && this.pay.is_china === false;
      },
      isDemand(): boolean {
        return this.pay && this.pay.pay_type === this.model.Pay.TYPE.DEMAND;
      },
      isSupply(): boolean {
        return this.pay && this.pay.pay_type === this.model.Pay.TYPE.SUPPLY;
      },
      isPrepay(): boolean {
        return this.pay && this.pay.category === this.model.Pay.CATEGORY.PREPAY;
      },
      // 非业务付款无合同
      isDirect(): boolean {
        return this.pay && this.pay.category === this.model.Pay.CATEGORY.DIRECT;
      },
      // 非业务付款有合同
      isPurchase(): boolean {
        return this.pay && this.pay.category === this.model.Pay.CATEGORY.PURCHASE;
      },
      isLinkingPayment(): boolean {
        return this.pay && this.pay.flow_id && this.pay.flow.current_state.key === 'Analysis Checking Pay';
      },
      isLinkingPrepay(): boolean {
        return (
          this.pay && this.pay.flow && this.pay.flow && this.pay.flow.current_state.key === 'Accountant Linking Prepay'
        );
      },
      isLinkingRecv(): boolean {
        return this.pay && this.pay.flow && this.pay.flow.current_state.key === 'Accountant Linking Recv';
      },
      canClose(): boolean {
        const STATUS = this.model.FlowState.STATUS;
        const roleCan = this.isAdmin || this.isAnalysis || this.isAccountant;
        const noLinkedBill = !this.showLinkedBillList || this.showLinkedBillList.length === 0;
        return (
          this.isDemand &&
          roleCan &&
          this.pay &&
          ![STATUS.OPEN_TO_CANCELED, STATUS.CANCELED, STATUS.FINISHED].includes(this.pay.state_status) &&
          noLinkedBill
        );
      },
      canReopen(): boolean {
        const STATUS = this.model.FlowState.STATUS;
        const roleCan = this.isAdmin || this.isAnalysis || this.isAccountant;
        return (
          (this.isDemand || this.isPrepay) &&
          roleCan &&
          this.pay &&
          [STATUS.OPEN_TO_CANCELED, STATUS.CANCELED, STATUS.FINISHED].includes(this.pay.state_status)
        );
      },
      canBillAction(): boolean {
        return (
          (this.isDemand || this.isPrepay) &&
          (this.isLinkingPayment || this.isLinkingRecv || this.isLinkingPrepay) &&
          (this.isFinance || this.user === this.pay.owner_id || this.isGod)
        );
      },
      canAssignToMe(): boolean {
        let current_state: any = {};
        const ROLE = this.model.FlowState.ROLE;
        if (this.pay && this.pay.flow_id) {
          current_state = this.pay.flow.current_state;
        }
        const roleMap = { FBP: 'ANALYSIS', Acct: 'ACCOUNTANT', Legal: 'LEGAL', Treasury: 'CAPITAL' };
        return (
          (this.user.id !== current_state.owner_id || !current_state.deal_owners.includes(this.user.id)) &&
          [ROLE.FBP, ROLE.LEGAL, ROLE.ACCT, ROLE.TREASURY].includes(current_state.role) &&
          !isEmptyData(this.getMyTeamByType(this.model.Team.TYPE[roleMap[ROLE[current_state.role]]]))
        );
      },
      adminActions() {
        const ret: { text: string; click: Fn }[] = [];
        const canDel = !(
          this.pay &&
          this.pay.state_status === this.model.FlowState.STATUS.FINISHED &&
          !this.pay.is_virtual
        );

        if (this.isAdmin || !isProd || canDel) {
          ret.push({ text: 'Delete', click: () => this.deletePay() });
        }
        if (this.isGod || !isProd) {
          ret.push({ text: 'Json', click: () => this.showPayJson() });
        }
        return ret;
      },
      configLinkedBillList(): BasicTableProps {
        const config: BasicTableProps = {
          title: '账单',
          useSearchForm: false,
          columns: [
            { title: 'Group', dataIndex: 'team_group_name' },
            { title: 'Team', dataIndex: 'team_short_name' },
            { title: '跟进人', dataIndex: 'rd_owner_name' },
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: {
                customRender: 'rd_state_name',
              },
            },
            { title: '发票', dataIndex: 'invoice_money', round: 2 },
            { title: '发票号码', dataIndex: 'invoice_number' },
            { title: 'All Linked', dataIndex: 'linked_money', round: 2 },
            { title: 'Unlinked', dataIndex: 'to_pay_money', round: 2 },
            {
              title: 'Invoice linked',
              dataIndex: 'invoice_linked_amount',
              colorField: 'color',
              slots: {
                customRender: 'invoice_linked_amount',
              },
              round: 2,
            },
            { title: 'Linked in this Pay', dataIndex: 'this_linked_money', round: 2 },
            // { title: 'Link', value: 'link' },
          ],
          initItem: (item) => {
            item.invoice_linked_amount = item.invoice_linked_amount || 0;
            item.color = item.finance_invoice_money === item.invoice_linked_amount ? 'black' : 'red';
          },
        };
        if (this.canBillAction) {
          config.actionColumn = {
            title: '操作',
            dataIndex: 'action',
            slots: { customRender: 'action' },
          };
        }
        return config;
      },
      showLinkedBillList(): Recordable[] {
        if (this.sameCompanyBillList) {
          return this.sameCompanyBillList.filter((x) => x.linked_money && x.link.id && x.link.pay_id === this.pay.id);
        } else {
          return [];
        }
      },
      linkBillList(): Recordable[] {
        if (this.sameCompanyBillList) {
          if (this.isSupply) {
            return this.sameCompanyBillList
              .filter(
                (x) =>
                  [
                    this.model.BillState.STATE.GIVING_MONEY,
                    this.model.BillState.STATE.GIVING_OR_GETTING_MONEY,
                    this.model.BillState.STATE.CLOSED,
                  ].includes(x.state_value) && x.this_link_cap !== 0
              )
              .map((item, index) => ({ key: index, ...item }));
          } else {
            return this.sameCompanyBillList
              .filter(
                (x) =>
                  [
                    this.model.BillState.STATE.GETTING_MONEY,
                    this.model.BillState.STATE.GIVING_OR_GETTING_MONEY,
                    this.model.BillState.STATE.CLOSED,
                  ].includes(x.state_value) && x.this_link_cap !== 0
              )
              .map((item, index) => ({ key: index, ...item }));
          }
        } else {
          return [];
        }
      },
      linkBillListColumns(): BasicColumn[] {
        const ret = [
          {
            title: 'Bill Name',
            key: 'name',
            dataIndex: 'name',
            slots: { customRender: 'name' },
          },
          {
            title: 'Invoice Money',
            key: 'invoice_money',
            dataIndex: 'invoice_money',
            customRender: (text) => {
              return formatMoneyByRound(text.text);
            },
          },
        ];
        if (this.isDemand) {
          ret.push({
            title: 'To Get',
            key: 'to_pay_money',
            dataIndex: 'to_pay_money',
            customRender: (text) => formatMoneyByRound(text.text),
          });
          ret.push({
            title: 'Get Money Deadline',
            key: 'pay_money_deadline',
            dataIndex: 'pay_money_deadline',
            slots: { customRender: 'pay_money_deadline' },
          });
        } else {
          ret.push({
            title: 'To Pay',
            key: 'to_pay_money',
            dataIndex: 'to_pay_money',
            customRender: (text) => formatMoneyByRound(text.text),
          });
          ret.push({
            title: 'Plan Pay Time',
            key: 'plan_pay_time',
            dataIndex: 'plan_pay_time',
            slots: { customRender: 'plan_pay_time' },
          });
        }
        ret.push({
          title: 'Link Part',
          key: 'link_money',
          dataIndex: 'link_money',
          slots: { customRender: 'link_money' },
        });

        return ret;
      },
      get_drop_down_menus(): Recordable[] {
        const ret: Recordable[] = [
          {
            show:
              this.hasPermission('CanViewPropertySwitch') &&
              [this.model.Pay.CATEGORY.NORMAL, this.model.Pay.CATEGORY.PREPAY].includes(this.pay.category) &&
              ![this.model.Pay.TYPE.SUPPLY].includes(this.pay.pay_type),
            label: `切换为${this.pay.category === this.model.Pay.CATEGORY.NORMAL ? 'Prepay' : 'Normal'}`,
            onClick: () => {
              const params = {
                category:
                  this.pay.category === this.model.Pay.CATEGORY.NORMAL
                    ? this.model.Pay.CATEGORY.PREPAY
                    : this.model.Pay.CATEGORY.NORMAL,
              };
              edit_demand_pay({ meta: { edit_mode: 'edit', pay_id: this.pay.id }, data: params }).then((res) => {
                this.afterEditPay(res);
              });
            },
          },
          {
            show: this.isGod,
            label: '编辑',
            onClick: () => this.godEditPay(this.pay, this),
          },
          {
            show: this.isOne,
            label: '编辑Purchase',
            onClick: () => this.editPurchasePay('edit', this.pay, this),
          },
          {
            show: this.isGod,
            label: '变更记录',
            onClick: () => this.$showChange(this.pay, 'pay'),
          },
          {
            show: this.canClose,
            label: '关闭',
            onClick: () => this.closePay(),
          },
          {
            show: this.canReopen,
            label: '重启流程',
            onClick: () => this.reopenPay(),
          },
          {
            show: this.canAssignToMe,
            label: '认领',
            onClick: () => this.assignToMe(),
          },
          {
            show: this.isPurchase && !this.isProd,
            label: '付款拆分',
            onClick: () => this.splitPay(),
          },
          {
            show: this.isSupply,
            label: '打印',
            onClick: () => this.printPay(),
          },
        ];
        if (this.isAdmin && this.adminActions.length > 0) {
          this.adminActions.forEach((item) => {
            ret.push({
              label: item.text,
              onClick: item.click,
              show: true,
            });
          });
        }
        return ret.filter((x) => x.show);
      },
      splitPayColoums(): BasicColumn[] {
        return [
          {
            title: 'Group',
            dataIndex: 'group_id',
            slots: { customRender: 'group_id' },
          },
          {
            title: 'Bu',
            dataIndex: 'bu_id',
            slots: { customRender: 'bu_id' },
          },
          {
            title: 'Team',
            dataIndex: 'team_id',
            slots: { customRender: 'team_id' },
          },
          {
            title: '拆分金额',
            dataIndex: 'split_money',
            slots: { customRender: 'split_money' },
          },
          {
            title: '操作',
            dataIndex: 'colIndex',
            slots: { customRender: 'splitAction' },
          },
        ];
      },
      showSplitPayConfig(): BasicTableProps {
        const config: BasicTableProps = {
          title: '付款拆分',
          useSearchForm: false,
          columns: [
            { title: 'Group', dataIndex: 'group_name' },
            { title: 'BU', dataIndex: 'bu_name' },
            { title: 'Team', dataIndex: 'team_name' },
            { title: '拆分金额', dataIndex: 'split_money' },
          ],
        };
        return config;
      },
    },
    created() {
      if (this.payId === '') {
        this.$router.go(-1);
        appStore.SET_DRAWER_CONFIG(null);
        return;
      }
      this.getPayDetail(Number(this.payId));
    },
    methods: {
      isEmptyData,
      getPayDetail(id: number) {
        get_pay_item({ pay_id: id }).then((res) => {
          this.refreshPayRes(res);
        });
      },
      refreshPayRes(res: Recordable) {
        const pay = res.pay;
        this.initPayInfo(pay, this);
        this.pay = pay;
        this.initLinkBill();
        document.title = this.pay.name;
        // TODO this.showSplitPay =
      },
      doAfterEditPay(res) {
        if (res === 'delete') {
          this.pay = {};
          this.$router.go(-1);
        } else if (this.pay) {
          this.getPayDetail(Number(this.payId));
        }
      },
      afterEditPay(res) {
        this.doAfterEditPay(res);
      },
      formatMoneyByRound,
      formatTime,
      canFinish(bill) {
        return (
          (this.isGod || (this.canBillAction && eq(bill.to_pay_money, 0))) &&
          bill.state_value !== this.model.BillState.STATE.CLOSED &&
          !bill.is_purchase
        );
      },
      canReverse(bill) {
        return (
          (this.isGod || (this.canBillAction && eq(bill.to_pay_money, 0))) &&
          bill.state_value === this.model.BillState.STATE.CLOSED &&
          !bill.is_purchase
        );
      },
      printPay() {
        const vm = this;
        appStore.SET_PRINT_INFO({
          pay: vm.pay,
          // bill: vm.bill,
          linkBill: vm.showLinkedBillList,
        });
        this.$router.push({
          name: 'ERPPayPrint',
        });
      },
      closeRefresh(res) {
        this.afterEditPay(res);
        appStore.SET_ALERT_CONFIG(null);
        appStore.SET_WE_DIALOG(null);
        this.linkBillDialog = false;
        this.initLinkBill();
      },
      deletePay() {
        this.$showAlert('Warning', 'Are you sure to DELETE this pay?', () => {
          delete_pay({ pay_id: this.pay.id }).then(() => {
            this.afterEditPay('delete');
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      showPayJson() {
        this.showJson(this.pay);
      },
      async catchFlowFlag(flag, _) {
        if (flag === 'FLAG_LINK_BILL') {
          this.openLinkDialog();
        } else if (flag === 'FLAG_EDIT_SUPPLY_PAY') {
          this.editSupplyPay(this.pay, this);
        } else if (flag === 'FLAG_EDIT_PURCHASE_PAY') {
          this.editPurchasePay('edit', this.pay, this);
        } else if (flag === 'FLAG_EDIT_DEMAND_PAY') {
          this.editDemandPay('edit', this.pay, this);
        } else if (flag === 'FLAG_EDIT_INVOICE') {
          const pageConfig = {
            pathSuffix: 'purchase',
            title: 'Purchase Invoices',
            customer: '销售方',
            customerCategory: 'Purchase',
            type: this.model.Invoice.TYPE.SUPPLY,
          };

          const initInvoice = {
            we_company_id: this.pay.we_company_id,
            company_id: this.pay.company_id,
            currency_id: this.pay.currency_id,
            date: this.formatTime(Date(), 'YYYY-MM-DD'),
            it_amount: this.pay.pay_money,
            rat: 0,
            et_amount: this.pay.pay_money,
          };
          this.editInvoice(
            this,
            'add',
            pageConfig,
            initInvoice,
            this.pay.bill_linked.map((x) => x.bill_id)
          );
          return;
        } else if (flag === 'FLAG_EDIT_DIRECT_PAY') {
          this.addDirectPayment('edit', this.pay, this);
        }
      },
      assignToMe() {
        const originUser = this.pay.flow.current_state.rd_owner;
        const tip = `Are you sure to assign it from ${originUser} to yourself?`;
        const params = { pay_id: this.pay.id };
        const callback = () => {
          assign_pay_to_me(params).then((res) => {
            this.afterEditPay(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        };
        this.$showAlert('Warning', tip, callback);
      },
      closePay() {
        this.$showAlert('Warning', 'Close this Trans?', () => {
          close_pay({ pay_id: this.pay.id }).then((res) => {
            this.afterEditPay(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      reopenPay() {
        this.$showAlert('Warning', 'Reopen this Trans?', () => {
          reopen_pay({ pay_id: this.pay.id }).then((res) => {
            this.afterEditPay(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      initLinkBill() {
        get_same_company_bill_list({ pay_id: this.pay.id }).then((res) => {
          this.initBillListSimple(this, res.bill_list);
          if (this.pay.team_id) {
            this.pay.gm_id = this.teamMap[this.pay.team_id].p_gm_id;
          }

          res.bill_list.forEach((bill) => {
            bill.team_group_name = this.teamMap[bill.team_id].team_group.name;
            bill.team_short_name = this.teamMap[bill.team_id].short_name;
            bill.gm_id = this.teamMap[bill.team_id].p_gm_id;
            if (bill.link) {
              this.pay.gm_id = bill.gm_id;
              bill.is_checked = true;
              bill.this_link_cap = bill.to_pay_money + bill.link.link_money;
              bill.linked_by_other = bill.linked_money - bill.link.link_money;
            } else {
              bill.link = { id: 0, pay_id: this.pay.id, bill_id: bill.id, link_money: null };
              bill.is_checked = false;
              bill.this_link_cap = bill.to_pay_money;
              bill.linked_by_other = bill.linked_money;
            }
            bill.this_linked_money = bill.link.link_money;
            bill.to_pay_money_no_link = bill.to_pay_money + (bill.linked_money || 0);
          });
          this.BillSelectedRowKeys = [];
          this.linkBillList.forEach((x, index) => {
            if (x.is_checked) {
              this.BillSelectedRowKeys.push(index);
            }
          });
          this.sameCompanyBillList = res.bill_list;
          this.linkBillChange();
          this.changeAllUnlinked();
        });
      },
      openLinkDialog() {
        this.initLinkBill();
        this.linkBillDialog = true;
      },
      linkBillCheckAllChange(val) {
        this.BillSelectedRowKeys = Array.from(new Set(val));
        if (val.length === 0) {
          this.linkBillList.forEach((x) => {
            x.is_checked = false;
          });
        } else {
          this.BillSelectedRowKeys.forEach((x) => {
            this.linkBillList[x].is_checked = true;
          });
        }
        this.syncLinkCheck();
      },
      getCheckboxProps(record) {
        return {
          disabled: record.pay_money_done && record.is_checked && record.this_link_cap > 0, // Column configuration not to be checked
          name: record.name,
        };
      },
      linkBillChange() {
        const allChecked = !this.linkBillList.find((x) => !x.is_checked);
        const noChecked = !this.linkBillList.find((x) => x.is_checked);
        if (allChecked) {
          this.linkBillCheckAll = true;
        } else if (noChecked) {
          this.linkBillCheckAll = false;
        } else {
          this.linkBillCheckAll = false;
        }
        this.syncLinkCheck();
      },
      syncLinkCheck() {
        this.linkBillList.forEach((x) => {
          if (x.is_checked && isEmptyData(x.link.link_money)) {
            x.link.link_money = 0;
          } else if (!x.is_checked && !isEmptyData(x.link.link_money)) {
            x.link.link_money = null;
          }
        });
        this.changeAllUnlinked();
      },
      changeAllUnlinked() {
        let allLinked = 0;
        if (this.sameCompanyBillList) {
          this.sameCompanyBillList.forEach((x) => {
            allLinked += x.link.link_money ? parseFloat(x.link.link_money) : 0;
          });
        }
        this.linkBillUnlinked = this.pay.pay_money + (this.pay.fee_money || 0) - allLinked;
        this.linkBillAllLinked = allLinked;
      },
      autoLinkBill(bill) {
        bill.link.link_money = formatMoneyByRound(bill.this_link_cap).replace(/,/g, '');
        this.linkMoneyChanged(bill);
        if (!this.isLinkingPayment && this.linkBillUnlinked < 0) {
          bill.link.link_money = formatMoneyByRound(parseFloat(bill.link.link_money) + this.linkBillUnlinked).replace(
            /,/g,
            ''
          );
          this.linkMoneyChanged(bill);
        }
      },
      linkMoneyChanged(bill) {
        const linked_by_this = bill.link.link_money || 0;
        bill.to_pay_money = bill.to_pay_money_no_link - (parseFloat(linked_by_this) + bill.linked_by_other);
        this.changeAllUnlinked();
      },
      linkBillForPay() {
        if (!this.isLinkingPayment && this.linkBillUnlinked < -0.01) {
          this.$showAlert('Error', 'You linked too much money, unlinked money cannot be negative.');
          return;
        }

        if (this.linkForm?.validate()) {
          this.$showAlert('Warning', 'Are you sure to link these bills?', () => {
            const params = {
              is_linking_payment: this.isLinkingPayment,
              pay_id: this.pay.id,
              pay_link_list: this.linkBillList.map((x) => x.link),
            };
            link_bill_for_pay(params).then((res) => {
              this.closeRefresh(res);
            });
          });
        }
      },
      finishBill(bill) {
        this.$showAlert('Warning', `Are you sure to <b>Finish</b> ${bill.name}?`, () => {
          finish_bill({ bill_id: bill.id, pay_id: this.pay.id }).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      reverseBill(bill) {
        this.$showAlert('Warning', `Are you sure to <b>Reverse</b> ${bill.name}?`, () => {
          reverse_bill({ bill_id: bill.id, pay_id: this.pay.id }).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      handleActions(record: any, _column: BasicColumn): ActionItem[] {
        return [
          {
            label: 'Finish',
            show: this.canFinish(record),
            onClick: () => this.finishBill(record),
          },
          {
            label: 'Reverse',
            show: this.canReverse(record),
            onClick: () => this.reverseBill(record),
          },
        ];
      },
      splitPay() {
        this.splitPayItems = [{}];
        this.splitPayDialog = true;
        // TODO get_split_items
      },
      groupChange(index) {
        const splitItem = this.splitPayItems.find((_, i) => i === index);

        splitItem.bu_id = null;
        splitItem.team_id = null;
        // 默认最后一个填充所有金额
        splitItem.split_money = this.pay.pay_money - sum(this.splitPayItems.map((x) => x.split_money || 0));
        splitItem.bu_items = uniqBy(
          this.group_bu_items
            .filter((item) => item.group_id === splitItem.group_id)
            .map((item) => ({ id: item.bu_id, name: item.bu_name }))
            .filter((item) => !isEmptyData(item.name)),
          'id'
        );
        splitItem.team_items = uniqBy(
          this.group_bu_items
            .filter((item) => item.group_id === splitItem.group_id)
            .map((item) => ({ id: item.team_id, name: item.team_name }))
            .filter((item) => !isEmptyData(item.name)),
          'id'
        );
      },
      addCol() {
        this.splitPayItems.push({});
      },
      deleteCol(index) {
        this.splitPayItems.splice(index, 1);
        // 默认最后一个填充所有金额
        const endSplitItem = this.splitPayItems.slice(-1)[0];
        endSplitItem.split_money =
          endSplitItem.split_money + this.pay.pay_money - sum(this.splitPayItems.map((x) => x.split_money || 0));
      },
      saveSplitPay() {
        const items = this.splitPayItems.filter((x) => x.group_id);
        console.log(items);
        // TODO save
        this.splitPayDialog = false;
      },
    },
  });
